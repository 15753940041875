import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';

const Header = dynamic(() => import('./Header'));
const Footer = dynamic(() => import('./Footer'));
const ModalContainer = dynamic(() => import('../modals/ModalContainer'));
const AskDella = dynamic(() => import('../cards/AskDella'));
const Img = dynamic(() => import('../Img'));

import { generateUrl, getCurrentBreakpoint, setBrowserId, useStorage } from "../../utils/Helper";
import * as Sentry from "@sentry/nextjs";
import { HeaderProps } from './Header';


interface IProps {
    children: React.ReactNode
    title: string
    description: string
    image?: string
    withoutGA?: boolean
    header?: React.ReactNode
    noFooter?: boolean
    usePadding?: boolean
    breadcrumb?: IInputBreadcrumb
    isFooterPushed?: boolean
    customData?: {
        [key: string]: any
    }
    customDataFAQ?: {
        [key: string]: any
    }
    backgroundColor?: string
    withDella?: boolean
    dellaZIndex?: string
    preloadImage?: string | string[]
    preconnectUrl?: string | string[]
    dnsPrefetchUrl?: string | string[]
    referalPage?: boolean
    footerExtraPadding?: string
    noIndex?: boolean
    headerzIndex?: string
}

export interface IInputBreadcrumb {
    [key: string]: string
}

interface IBreadcrumb {
    "@context": string
    "@type": string
    "itemListElement": {
        "@type": "ListItem"
        position: number
        name: string
        item?: string
    }[]
}

export default function Master({ children, title, description, image, url, id_url, en_url, lang, isTransparent, isBlue, langCode, withoutGA, header, noFooter, usePadding, withAds, setIsLoginPage, isLoginModalOpen, breadcrumb, isSidebarOpen, setIsSidebarOpen, isFooterPushed, customData, customDataFAQ, backgroundColor, sidebarZIndex, withDella, dellaZIndex, preloadImage, preconnectUrl, dnsPrefetchUrl, footerExtraPadding, referalPage, headerzIndex, newHeaderType, noIndex }: IProps & HeaderProps) {
    const [isAdsOpen, setAdsOpen] = useState(false)
    const [isInit, setIsInit] = useState(true)
    const [sidebarOpenDella, setSidebarOpenDella] = useState(false)
    const [isManualDellaModalOpen, setIsManualDellaModalOpen] = useState(false)
    const [isAutoDellaModalOpen, setIsAutoDellaModalOpen] = useState(false)
    const [hasOpened, setHasOpened] = useState(false)
    const [hasOpenedThisPage, setHasOpenedThisPage] = useState(false)

    useEffect(() => {
        setBrowserId()
        const breakpoint = getCurrentBreakpoint()
        setAdsOpen(withAds ? (breakpoint !== 'xs' && breakpoint !== 'sm') : false)
        setIsInit(false)

        useStorage(() => {
            let hasOpenedBefore = window.localStorage.getItem('has_opened_della')
            if (hasOpenedBefore === '1') {
                setHasOpened(true)
            }
        })

        return () => {
            setIsManualDellaModalOpen(false)
            setIsAutoDellaModalOpen(false)
            setHasOpenedThisPage(false)
            setHasOpened(false)
        }
    }, [])

    const [traceId] = useState(Sentry.getCurrentHub().getScope()?.getSpan()?.toTraceparent());

    useEffect(() => {
        let timeout: null | NodeJS.Timeout = null

        if (isManualDellaModalOpen || isAutoDellaModalOpen) {
            setHasOpened(true)
            setHasOpenedThisPage(true)
            useStorage(() => {
                window.localStorage.setItem('has_opened_della', '1')
            })
        }

        if (!hasOpened) {
            timeout = setTimeout(() => {
                setIsAutoDellaModalOpen(true)
            }, 300000)
        }

        // setTraceId(Object.values(Sentry.getCurrentHub().traceHeaders())[0]);

        return () => {
            if (timeout) {
                window.clearTimeout(timeout)
            }
        }
    }, [hasOpened, isAutoDellaModalOpen, isManualDellaModalOpen])

    const constructBreadcrumb = (breadcrumb: IInputBreadcrumb) => {
        let output: IBreadcrumb = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": []
        }

        Object.entries(breadcrumb).forEach(([key, value], index) => {
            output.itemListElement.push({
                "@type": "ListItem",
                position: index + 1,
                name: key,
                item: value
            })
        })

        return output
    }

    const handleSidebarDella = (status: boolean) => {
        setSidebarOpenDella(status)
    }

    return (
        <>
            <Head>
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="height=device-height,width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <title>{title}</title>
                {
                    (noIndex || process.env.NEXT_PUBLIC_FORCE_NOINDEX) &&
                    <meta name="robots" content="noindex, nofollow" />
                }
                <meta name="google-site-verification" content="pccnFefLIY5MklKIk9BIdTGGAAC5LuRk2anu3QubeE0" />
                <meta name="description" content={description} />
                <meta name="ahrefs-site-verification" content="efd9c5385600fd8425f75d006f556b4b271b88e54d114502e7ef46ecbd1de335" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@jendela360" />
                <meta name="twitter:creator" content="@jendela360" />
                <meta property="fb:app_id" content="260180081106004" />
                <meta name="facebook-domain-verification" content="054cbcn0sb6xmlbar8ehkiusmt6ttd" />
                <meta property="og:site_name" content="Jendela360" />
                <meta property="og:url" content={generateUrl(langCode || 'id', url || '')} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description || 'Temukan 7414 unit apartemen 1BR/ 2BR/ 3BR disewakan di Jakarta, harga mulai dari 3.340.000x12bln. ✓12x cicilan, ✓360 virtual tour.'} />
                <meta property="og:image" content={image || `https://assets.jendela360.com/jendela/assets/images/default-thumbnail-unit.jpg`} />
                <meta name="google" content="notranslate" />

                <link rel="canonical" href={generateUrl(langCode || 'id', url || '')} />
                <link rel="alternate" hrefLang="en" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/en${en_url || url}`} />
                <link rel="alternate" hrefLang="id" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}${id_url || url}`} />
                <link rel="alternate" hrefLang="x-default" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}${id_url || url}`} />

                <meta name="sentry-trace" content={`${traceId}`} />

                <meta name='application-name' content='Jendela360' />
                <meta name='apple-mobile-web-app-capable' content='yes' />
                <meta name='apple-mobile-web-app-status-bar-style' content='default' />
                <meta name='apple-mobile-web-app-title' content='Jendela360' />
                <meta name='format-detection' content='telephone=no' />
                <meta name='mobile-web-app-capable' content='yes' />
                <meta name='theme-color' content='#FFFFFF' />
                <link rel='manifest' href='/manifest.json' />
                <link rel="shortcut icon" href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/favicon.ico`} type="image/x-icon" />
                <link rel="apple-touch-icon" href={`https://assets.jendela360.com/jendela/assets/images/mobile-favicon.png`} />
                <link rel="apple-touch-icon" sizes="72x72" href={`https://assets.jendela360.com/jendela/assets/images/mobile-favicon.png`} />
                <link rel="apple-touch-icon" sizes="114x114" href={`https://assets.jendela360.com/jendela/assets/images/mobile-favicon.png`} />
                <link rel="apple-touch-icon" sizes="128x128" href={`https://assets.jendela360.com/jendela/assets/images/mobile-favicon.png`} />

                <link rel="preconnect" href="https://www.google-analytics.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap" rel="stylesheet"></link>

                {
                    preconnectUrl ?
                        typeof preconnectUrl === 'string' ?
                            <link rel="preconnect" href={preconnectUrl} />
                            :
                            preconnectUrl.map((preImage, index) =>
                                <link key={index} rel="preconnect" href={preImage} />
                            )
                        :
                        null
                }

                {
                    dnsPrefetchUrl ?
                        typeof dnsPrefetchUrl === 'string' ?
                            <link rel="dns-prefetch" href={dnsPrefetchUrl} />
                            :
                            dnsPrefetchUrl.map((preImage, index) =>
                                <link key={index} rel="dns-prefetch" href={preImage} />
                            )
                        :
                        null
                }

                {
                    preloadImage ?
                        typeof preloadImage === 'string' ?
                            <link rel="preload" href={preloadImage} as="image" />
                            :
                            preloadImage.map((preImage, index) =>
                                <link key={index} rel="preload" href={preImage} as="image" />
                            )
                        :
                        null
                }

                {
                    !!breadcrumb ?
                        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(constructBreadcrumb(breadcrumb)) }} />
                        : null
                }

                {
                    (!!customData && ('ldJson' in customData) && customData.ldJson !== null) ?
                        customData.ldJson instanceof Array ?
                            customData.ldJson.map((ldJson: any) => (
                                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }} />
                            )) :
                            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(customData.ldJson) }} /> :
                        null
                }

                {
                    (!!customDataFAQ && ('ldJson' in customDataFAQ) && customDataFAQ.ldJson !== null) &&
                    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(customDataFAQ.ldJson) }} />
                }

                {/* Google Analytics */}
                {
                    withoutGA ?
                        null
                        :
                        <>
                            <script dangerouslySetInnerHTML={{
                                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
                            }}>
                            </script>
                            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}></script>
                            <script
                                dangerouslySetInnerHTML={{
                                    __html: `
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                
                                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
                                `
                                }}
                            />
                        </>
                }

                {/* Crazy Egg */}
                {/* {(!withoutGA) &&
                    <script type="text/javascript" src="https://script.crazyegg.com/pages/scripts/0071/8967.js" async={true} ></script>
                } */}
            </Head>

            {/* NoScript Google Analytics */}
            {
                withoutGA ?
                    <>
                        <noscript>
                            <iframe src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`} height="0" width="0" style={{ display: "none", visibility: "hidden" }} />
                        </noscript>
                    </>
                    : ""
            }

            <div className={`relative transition-all duration-150 ease-linear ${backgroundColor || ''}`}>
                {
                    header ||
                    <Header
                        url={url}
                        isTransparent={isTransparent}
                        isBlue={isBlue}
                        langCode={langCode}
                        lang={lang}
                        withAds={isAdsOpen}
                        handleSidebarDella={handleSidebarDella}
                        setIsLoginPage={setIsLoginPage}
                        isLoginModalOpen={isLoginModalOpen}
                        isSidebarOpen={isSidebarOpen}
                        setIsSidebarOpen={setIsSidebarOpen}
                        sidebarZIndex={sidebarZIndex}
                        newHeaderType={newHeaderType}
                        headerzIndex={headerzIndex}
                    />
                }
                {
                    (withDella && !isInit && !sidebarOpenDella) &&
                    <>
                        <div className={`fixed bottom-0 right-0 ${dellaZIndex || 'z-20 md:z-10'}`} style={{ pointerEvents: 'none' }}>
                            <button
                                onClick={() => setIsManualDellaModalOpen(true)}
                                className={`static focus:outline-none ${isManualDellaModalOpen ? 'opacity-0' : 'opacity-100'} transform translate-y-2`}
                                style={{ pointerEvents: (isManualDellaModalOpen || isAutoDellaModalOpen) ? 'none' : 'auto' }}
                            >
                                <div className="relative" style={{ width: 120, height: 120 }}>
                                    <Img src="https://d2jgpj59n9tcev.cloudfront.net/assets/new-tanya-della.png" alt='ask della' />
                                </div>
                            </button>

                            <div
                                className={`bg-white md:rounded-5px transform fixed md:static inset-0 w-full md:w-auto h-full md:h-auto ${isManualDellaModalOpen ? 'translate-y-0 md:mr-8 md:mb-8 opacity-100' : 'translate-y-full opacity-0'} transition-all duration-100 ease-linear`}
                                style={{ pointerEvents: isManualDellaModalOpen ? 'auto' : 'none' }}
                            >
                                {
                                    isManualDellaModalOpen ?
                                        <AskDella
                                            hasOpened={hasOpenedThisPage}
                                            lang={lang}
                                            langCode={langCode === 'en' ? 'en' : 'id'}
                                            close={() => setIsManualDellaModalOpen(false)}
                                            className="md:shadow-della h-full md:h-auto"
                                            nextButtonClassName="w-full md:w-auto text-center"
                                            backButtonClassName="w-full md:w-auto text-center"
                                        />
                                        : null
                                }
                            </div>
                        </div>
                        <ModalContainer
                            isOpen={isAutoDellaModalOpen}
                            noPadding
                            containerClassName="self-center"
                            close={() => setIsAutoDellaModalOpen(false)}
                        >
                            <AskDella
                                hasOpened={hasOpenedThisPage}
                                lang={lang}
                                langCode={langCode === 'en' ? 'en' : 'id'}
                                close={() => setIsAutoDellaModalOpen(false)}
                                className="max-w-ask-della md:max-w-0"
                            />
                        </ModalContainer>
                    </>
                }
                <div className={`${!referalPage && ' min-h-screen'} ${isFooterPushed ? 'flex flex-col' : ''} ${!isTransparent ? (newHeaderType === 'listing-sewa' ? '' : (usePadding ? 'pt-nav-mobile lg:pt-nav-desktop' : 'mt-nav-mobile lg:mt-nav-desktop')) : ''}`}>
                    {children}
                    {
                        !noFooter ?
                            <Footer
                                lang={lang}
                                langCode={langCode}
                                isFooterPushed={isFooterPushed}
                                footerExtraPadding={footerExtraPadding}
                                newFooterType={newHeaderType}
                            />
                            : null
                    }
                </div>
            </div>
        </>
    )
}
